* {
  box-sizing: border-box;
}

html,
body,
canvas,
.scene,
#root,
#container {
  width: 100%;
  max-height: 100vh;
  max-width: 100wh;
  height: 100%;
  margin: 0;
  padding: 0;
}
.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
  border: 2px solid white;
}

.btn{
  position: absolute;
  width: 50vw;
  height: 5vw;
  top: 45vh;
  left: 25%;
  margin: 0 auto;
  z-index: 100;
  background-color: rgb(50, 50, 255);
  color: white;
  border-color: white;
}